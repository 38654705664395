export const openPip = async (video: HTMLVideoElement) => {
	if (video !== document.pictureInPictureElement) {
		if (video.readyState >= 1) {
			await video.requestPictureInPicture();
			video.addEventListener("enterpictureinpicture", () => {
				if (video.paused) {
					video.play();
				}
			});
		} else {
			video.addEventListener("loadedmetadata", () => {
				video.requestPictureInPicture();
			});
		}
	} else {
		return;
	}
};

export const closePip = async () => {
	if (document.pictureInPictureElement) {
		await document.exitPictureInPicture();
	}
};

export const doPictureInPicture = (status: string, video: HTMLVideoElement) => {
	const supported = document.pictureInPictureEnabled;
	if (supported) {
		if (!document.pictureInPictureElement) {
			openPip(video);
		}
	}
};
