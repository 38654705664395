import React, {
	useEffect,
	useState,
	useContext,
	useRef,
	SetStateAction,
	Dispatch,
} from "react";
import { DataContext } from "../../context/DataContext";
// @ts-ignore

import { useDispatch, useSelector } from "react-redux";
import VideoControls from "../VideoControls/VideoControls";
import "./Player.scss";
import { EventStatus, PlayerAction } from "../../utils/constants";
import PlayerTop from "../PlayerTop/PlayerTop";
import { Muted } from "../FiraIcons";
import ChatDialog from "../ChatDialog/ChatDialog";
import MessagePopUp from "../MessagePopUp/MessagePopUp";

import {
	optionsType,
	RecordingType,
	RootState,
	VODType,
} from "../../utils/types";
import { findVideo, sendPlayVOD } from "../../utils/actions";
import Play from "../FiraIcons/Play";
import CounterBack from "../FiraIcons/CounterBack";
import CounterNext from "../FiraIcons/CounterNext";
import Pause from "../FiraIcons/Pause";
import VideoJS from "./VideoJs";
import SharePopUp from "../SharePopUp/SharePopUp";
import { closePopUpActionCreator } from "../../store/PopUpActions";

import { PlayerLoadingState } from "../PlayerLoadingState/PlayerLoadingState";
import { AgoraPlayer } from "../AgoraPlayer/AgoraPlayer";
import { usePlayerContext } from "../../utils/PlayerContext";
import { closePip, openPip } from "../../utils/pipHandler";
import BottomSheetFiraShoppingCart from "../BottomSheetFiraShoppingCart/BottomSheetFiraShoppingCart";
import VideoProgressBar from "../VideoProgressBar/VideoProgressBar";
import ProductDetail from "../ProductDetail/ProductDetail";
import MobileSheetWrapper from "../ProductDetail/MobileSheetWrapper";
import { usePlayerState } from "../../context/PlayerStateProvider";

type PlayerType = {
	existChat: boolean;

	urlRecording: string;
	setUrlRecording: Dispatch<SetStateAction<string>>;
	urlEventPreview: string;
	setDisplayPopUp: Dispatch<SetStateAction<boolean>>;
	displayPopUp: boolean;
	isVideoLoading: boolean;
	setIsVideoLoading: Dispatch<SetStateAction<boolean>>;
};

export default function Player({
	existChat,
	urlRecording,
	setUrlRecording,
	urlEventPreview,
	setDisplayPopUp,
	displayPopUp,
	isVideoLoading = false,
	setIsVideoLoading,
}: PlayerType) {
	const { broadcast, vod }: any = useContext(DataContext);
	const { videoPlayerRef } = usePlayerState();
	const reduxDispatch = useDispatch();
	const vodData: VODType = vod?.vod;
	const urlParams = new URLSearchParams(window.location.search);
	const shopping_cart = urlParams.get("shoppingCart") || "";
	const [videoExist, setvideoExist] = useState(true);
	const [videoHasError, setVideoHasError] = useState(false);
	const { isMuted, setIsMuted, multipleVideos } = usePlayerContext();
	const [isVideoPaused, setIsVideoPaused] = useState(false);
	const screenW = useSelector((state: RootState) => state.layout.screenWidth);
	const mainColor = broadcast?.firaWebConfiguration?.mainColor || "#010202";
	const [showControls, setShowControls] = useState(
		screenW > 981 ? false : true
	);
	const [showChat, setShowChat] = useState(false);
	const [showPlayControls, setShowPlayControls] = useState(false);
	const [counter, setCounter] = useState(0);
	const videoPaused = useRef<HTMLDivElement>(null);
	const [listUrlsRecording, setListUrlsRecording] = useState<RecordingType[]>(
		[]
	);

	const [showSharePopUp, setShowSharePopUp] = useState(false);

	const [videoJsOptions, setVideoJsOptions] = useState<optionsType>({
		src: "",
		type: "",
	});

	const [timeInterval, setTimeInterval] = useState<NodeJS.Timer>();

	const [currentTime, setCurrentTime] = useState(0);

	const closePopUp = () => {
		reduxDispatch(closePopUpActionCreator());
		setDisplayPopUp(false);
	};

	const handleMouseOver = () => {
		setShowControls(true);
	};

	const handleMouseOut = () => {
		setShowControls(false);
	};

	const handleMute = () => {
		if (videoPlayerRef && videoPlayerRef.current && !multipleVideos) {
			setIsMuted(!videoPlayerRef.current.muted);
			videoPlayerRef.current.muted = !videoPlayerRef.current.muted;

			return videoPlayerRef.current.muted;
		}
		setIsMuted(!isMuted);
	};

	const handlePlay = () => {
		const currentTime = document.querySelector("#fira_player")
			? (document.querySelector("#fira_player") as HTMLVideoElement).currentTime
			: 0;
		if (videoPlayerRef.current?.paused || videoPlayerRef.current?.ended) {
			if (broadcast.status == EventStatus.DONE) {
				videoPlayerRef.current?.play();
				sendPlayVOD(
					vodData.id,
					vod.connectionId,
					vodData.storeId,
					PlayerAction.PLAY,
					currentTime
				);
			} else {
				videoPlayerRef.current?.play();
			}
			setIsVideoPaused(false);
		} else {
			if (broadcast.status == EventStatus.DONE) {
				videoPlayerRef.current?.pause();
				sendPlayVOD(
					vodData.id,
					vod.connectionId,
					vodData.storeId,
					PlayerAction.PAUSE,
					currentTime
				);
			} else {
				videoPlayerRef.current?.pause();
			}
			setIsVideoPaused(true);
		}
	};

	const startVOD = async (videoList: RecordingType[]) => {
		const video = await findVideo(videoList);
		setListUrlsRecording(videoList);
		setUrlRecording(video.url);
		setvideoExist(true);
		setIsVideoPaused(true);
		setIsVideoLoading(false);
	};

	useEffect(() => {
		if (
			broadcast &&
			broadcast.status == "DONE" &&
			vodData &&
			vodData.videoUrls
		) {
			startVOD(vodData.videoUrls);
		}
	}, [vod]);

	useEffect(() => {
		setShowControls(screenW < 981);
	}, [screenW]);

	useEffect(() => {
		if (videoPaused && videoPaused.current) {
			if (isVideoPaused) {
				videoPaused.current.style.opacity = "1";
				videoPaused.current.style.visibility = "visible";
			} else {
				videoPaused.current.style.opacity = "0";
				videoPaused.current.style.visibility = "hidden";
			}
		}
	}, [isVideoPaused]);

	useEffect(() => {
		if (!isVideoPaused && videoPaused && videoPaused.current) {
			if (counter === 0 && videoPaused) {
				setShowPlayControls(false);
				videoPaused.current.style.opacity = "0";
				videoPaused.current.style.visibility = "hidden";
			} else {
				setShowPlayControls(true);
				videoPaused.current.style.opacity = "1";
				videoPaused.current.style.visibility = "visible";
				setTimeout(() => {
					setCounter(counter - 1);
				}, 1000);
			}
		}
	}, [counter]);

	const handleClickPlayer = () => {
		if (videoPaused && videoPaused.current && videoExist && screenW < 981) {
			if (!showPlayControls) {
				setShowPlayControls(true);
				setCounter(3);
			} else {
				if (!isVideoPaused) {
					setShowPlayControls(false);
					videoPaused.current.style.opacity = "0";
					videoPaused.current.style.visibility = "hidden";
					setCounter(0);
				}
			}
		}
	};

	const handleOnNext = () => {
		const player = videoPlayerRef.current;
		setCounter(3);
		if (player) {
			player.currentTime = player.currentTime + 15;
		}
	};

	const handleOnPrevious = () => {
		setCounter(3);
		const player = videoPlayerRef.current;
		if (player) {
			player.currentTime = player.currentTime - 15;
		}
	};

	const typeRecording =
		urlRecording.split(".").pop()?.toLocaleLowerCase() || "";

	const handleCloseProductPopup = () => {
		setDisplayPopUp(false);
	};

	useEffect(() => {
		if (urlRecording == "" || urlRecording == null) {
			setvideoExist(false);
		}

		if (urlRecording && urlRecording != "") {
			const videoOptions: optionsType = {
				src: urlRecording,
				type: typeRecording == "mp4" ? "video/mp4" : "application/x-mpegURL",
			};

			setVideoJsOptions(videoOptions);
			if (broadcast && broadcast.status !== "STARTED") {
				setIsVideoPaused(true);
			}
		}
	}, [urlRecording]);

	const handleOnEnded = () => {
		setIsVideoPaused(true);
	};

	const handleTimeUpdate = () => {
		if (videoPlayerRef.current) {
			setCurrentTime(videoPlayerRef.current.currentTime);
		}
	};

	useEffect(() => {
		if (videoPlayerRef && videoPlayerRef.current) {
			videoPlayerRef.current.addEventListener("leavepictureinpicture", () => {
				closePopUp();
			});

			videoPlayerRef.current.addEventListener("playing", () => {
				setvideoExist(true);
			});
		}

		return () => {
			if (videoPlayerRef && videoPlayerRef.current)
				videoPlayerRef.current.removeEventListener(
					"leavepictureinpicture",
					() => {
						closePopUp();
					}
				);
		};
	}, [videoPlayerRef.current]);

	useEffect(() => {
		if (broadcast.videoProvider === "AGORA") {
			if (displayPopUp) {
				if (videoPlayerRef.current) {
					openPip(videoPlayerRef.current);
				}
			} else {
				closePip();
			}
		}
	}, [displayPopUp]);

	return (
		<div
			className="player__wrapper"
			id="Player"
			onMouseOver={screenW > 981 ? handleMouseOver : () => null}
			onMouseOut={screenW > 981 ? handleMouseOut : () => null}
		>
			{showControls && screenW > 981 && (
				<>
					<div className="shadow-top"></div>
					<div className="shadow-bottom"></div>
				</>
			)}
			{screenW < 981 && <ChatDialog />}
			{screenW < 981 && shopping_cart && <BottomSheetFiraShoppingCart />}
			{showControls && videoExist && (
				<VideoControls
					isVideoMuted={isMuted}
					setIsVideoMuted={setIsMuted}
					isVideoPaused={isVideoPaused}
					setIsVideoPaused={setIsVideoPaused}
					showChat={showChat}
					isVod={vod ? true : false}
					shoppingCart={shopping_cart === "true" ? true : false}
					setShowChat={setShowChat}
					playerRef={videoPlayerRef}
					handlePlay={handlePlay}
					handleMute={handleMute}
					existChat={existChat}
					setShowSharePopUp={setShowSharePopUp}
					showSharePopUp={showSharePopUp}
					currentTime={currentTime}
					setCurrentTime={setCurrentTime}
					showPlayControls={showPlayControls}
				/>
			)}

			{(broadcast.status == "STARTED" ||
				(broadcast.status == "DONE" && urlRecording != "") ||
				((broadcast.status == "SCHEDULED" ||
					broadcast.status == "NOT_STARTED") &&
					(urlRecording !== "" || urlEventPreview != ""))) && (
				<div className="subscriberContainer" onClick={handleClickPlayer}>
					<PlayerTop
						showControls={showControls}
						setDisplayPopUp={setDisplayPopUp}
					/>
					{broadcast.status === "STARTED" &&
						broadcast.videoProvider === "AGORA" && (
							<AgoraPlayer
								setVideoExist={setvideoExist}
								subscriberData={broadcast.suscriberCredentialsDto}
							/>
						)}
					{broadcast.status == "DONE" && (
						<VideoJS
							playerRef={videoPlayerRef}
							options={videoJsOptions}
							setIsVideoPaused={setIsVideoPaused}
							interval={timeInterval}
							setUrlRecording={setUrlRecording}
							setvideoExist={setvideoExist}
							setVideoHasError={setVideoHasError}
							listUrlsRecording={listUrlsRecording}
							handleOnEnded={handleOnEnded}
							handlePopup={closePopUp}
							handleTimeUpdate={handleTimeUpdate}
							isMuted={isMuted}
						/>
					)}
					{(broadcast.status == "SCHEDULED" ||
						broadcast.status == "NOT_STARTED") &&
						videoJsOptions.src !== "" &&
						videoJsOptions.type !== "" && (
							<video
								ref={videoPlayerRef}
								onEnded={handleOnEnded}
								playsInline
								muted={isMuted}
								onTimeUpdate={handleTimeUpdate}
							>
								<source
									src={videoJsOptions.src}
									type={videoJsOptions.type}
								></source>
							</video>
						)}
					{(broadcast.status === "SCHEDULED" ||
						broadcast.status === "NOT_STARTED") &&
						urlEventPreview != "" &&
						!urlRecording && (
							<div className="eventPreview">
								<img src={urlEventPreview} alt="Preview-Image" />
							</div>
						)}

					{(broadcast.status === "STARTED" ||
						broadcast.status === "DONE" ||
						urlRecording) && (
						<div
							className={`videoMuted ${
								(!isMuted || isVideoPaused) && "d-none"
							}`}
						>
							<Muted
								onClick={handleMute}
								style={{
									fontSize: "10rem",
									color: mainColor,
									cursor: "pointer",
								}}
							/>
						</div>
					)}

					<div
						className="videoPaused"
						ref={videoPaused}
						style={{
							visibility: "hidden",
							transition: "visibility .5s, opacity .5s linear",
							gridTemplateColumns:
								broadcast.status == "DONE"
									? "max-content max-content max-content"
									: "max-content",
						}}
					>
						{broadcast.status == "DONE" && screenW < 981 && (
							<CounterBack
								style={{
									fontSize: "62px",
									color: "#fff",
								}}
								onClick={handleOnPrevious}
							/>
						)}

						{isVideoPaused && broadcast.status !== "STARTED" && (
							<Play
								onClick={handlePlay}
								style={{
									fontSize: "90px",
									color: "#fff",
								}}
							/>
						)}
						{!isVideoPaused && broadcast.status !== "STARTED" && (
							<Pause
								onClick={handlePlay}
								style={{
									fontSize: "90px",
									color: "#fff",
								}}
							/>
						)}
						{broadcast.status == "DONE" && screenW < 981 && (
							<CounterNext
								style={{
									fontSize: "62px",
									color: "#fff",
								}}
								onClick={handleOnNext}
							/>
						)}
					</div>

					{screenW < 981 &&
						showPlayControls &&
						(broadcast.status === "DONE" ||
							broadcast.status === "SCHEDULED" ||
							broadcast.status === "NOT_STARTED") && (
							<div className="progress-control-mobile">
								<VideoProgressBar
									videoRef={videoPlayerRef}
									currentTime={currentTime}
									setCurrentTime={setCurrentTime}
									color={mainColor}
								/>
							</div>
						)}

					{showChat && <div className="darkBg"></div>}
				</div>
			)}

			{broadcast.status == "DONE" &&
				urlRecording == "" &&
				!videoHasError &&
				!isVideoLoading && (
					<div className="reconnect_popup_wrapper">
						<PlayerLoadingState color={mainColor} />
					</div>
				)}

			{broadcast.status == "DONE" &&
				urlRecording == "" &&
				!videoHasError &&
				isVideoLoading && (
					<div className="reconnect_popup_wrapper">
						<PlayerLoadingState color={mainColor} />
					</div>
				)}

			{broadcast.status == "DONE" && urlRecording == "" && videoHasError && (
				<div className="reconnect_popup_wrapper">
					<MessagePopUp
						status={{
							currentStatus: "DONE_ERROR",
							mainColor,
							storeLogo: broadcast?.storeLogo,
						}}
					/>
				</div>
			)}

			{!videoExist && broadcast.status == "STARTED" && (
				<div className="reconnect_popup_wrapper">
					<MessagePopUp
						status={{
							currentStatus: "RECONNECTING",
							mainColor,
							storeLogo: broadcast?.storeLogo,
						}}
					/>
				</div>
			)}

			{/* PopUp */}
			{displayPopUp &&
				(screenW > 981 ? (
					<div className={`${"product-pop-up"}`}>
						<ProductDetail onClose={handleCloseProductPopup} />
					</div>
				) : (
					<MobileSheetWrapper>
						<ProductDetail onClose={handleCloseProductPopup} />
					</MobileSheetWrapper>
				))}

			{showSharePopUp && (
				<SharePopUp
					closePopUp={() => {
						setShowSharePopUp(false);
					}}
				/>
			)}
		</div>
	);
}
