import React from "react";
import screenfull from "screenfull";

import { getAdditionalFields } from "../utils/actions";

interface PlayerState {
	firaKey: string;
	firaSrc: string;
	additionalFields?: {
		[key: string]: string;
	};
	videoPlayerRef: React.MutableRefObject<HTMLVideoElement | null>;
	urlContruct(url: string, fields: { [key: string]: string }): string;
	toggleFullscreen(): void;
}

const PlayerStateContext = React.createContext<PlayerState | null>(null);

interface ProviderProps {
	firaKey: string;
	firaSrc: string;
	children: React.ReactNode;
	appRef: React.RefObject<HTMLDivElement>;
}
const PlayerStateProvider: React.FC<ProviderProps> = ({
	firaKey,
	firaSrc,
	children,
	appRef,
}) => {
	const videoPlayerRef = React.useRef<HTMLVideoElement | null>(null);
	const [additionalFields, setAdditionalFields] = React.useState<{
		[key: string]: string;
	}>();

	const toggleFullscreen = () => {
		if (screenfull.isEnabled && appRef.current) {
			if (screenfull.isFullscreen) {
				screenfull.exit(); // Exit fullscreen if already in fullscreen mode
			} else {
				screenfull.request(appRef.current); // Enter fullscreen if not in fullscreen mode
			}
		} else {
			console.error("Fullscreen is not supported by your browser.");
		}
	};

	const urlContruct = (url: string, fields: { [key: string]: string }) => {
		return url.replace(/{{(.*?)}}/g, (match, p1) => fields[p1] || match);
	};

	React.useEffect(() => {
		const loadAdditionalFields = async () => {
			const { data } = await getAdditionalFields(firaKey);
			setAdditionalFields(data);
		};

		loadAdditionalFields();
	}, []);

	return (
		<PlayerStateContext.Provider
			value={{
				firaKey,
				firaSrc,
				additionalFields,
				urlContruct,
				videoPlayerRef,
				toggleFullscreen,
			}}
		>
			{children}
		</PlayerStateContext.Provider>
	);
};

const usePlayerState = () => {
	const context = React.useContext(PlayerStateContext);
	if (!context) {
		throw new Error(
			"usePlayerState debe ser utilizado dentro de PlayerStateProvider"
		);
	}
	return context;
};

export { usePlayerState, PlayerStateProvider };
