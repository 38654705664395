import React, { FC, createContext, useContext, useState } from "react";
interface PlayerContextValue {
	isMuted: boolean;
	setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
	multipleVideos: boolean;
	setMultipleVideos: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlayerContext = createContext<PlayerContextValue | null>(null);

export const usePlayerContext = () => {
	const context = useContext(PlayerContext);
	if (!context) {
		throw new Error("usePlayerContext must be used within a PlayerProvider");
	}
	return context;
};

interface PlayerProviderProps {
	children: React.ReactNode;
}
export const PlayerProvider: FC<PlayerProviderProps> = ({ children }) => {
	const [isMuted, setIsMuted] = useState(true);
	const [multipleVideos, setMultipleVideos] = React.useState(false);

	return (
		<PlayerContext.Provider
			value={{ isMuted, setIsMuted, multipleVideos, setMultipleVideos }}
		>
			{children}
		</PlayerContext.Provider>
	);
};
