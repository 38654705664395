import React from "react";
import AgoraRTC, {
	IAgoraRTCClient,
	IRemoteAudioTrack,
	IRemoteVideoTrack,
	UID,
	ClientConfig,
} from "agora-rtc-sdk-ng";

import styles from "./AgoraPlayer.module.scss";

import { usePlayerContext } from "../../utils/PlayerContext";
import { usePlayerState } from "../../context/PlayerStateProvider";

const CLIENT_TYPE = "audience";

const rtcConfig: ClientConfig = {
	mode: "live",
	codec: "vp8",
};

type LiveStreamParamsType = {
	// A variable to hold a remote audio track.
	remoteAudioTrack?: IRemoteAudioTrack;
	// A variable to hold the remote user id.s
	remoteUid: UID;
};

interface Props {
	setVideoExist: React.Dispatch<React.SetStateAction<boolean>>;
	subscriberData: { channel: string; appId: string; token: string };
}
export const AgoraPlayer: React.FC<Props> = ({
	setVideoExist,
	subscriberData,
}) => {
	const videoPlayerWrapperRef = React.useRef<HTMLDivElement>(null);
	const agoraClientRef = React.useRef<IAgoraRTCClient | null>(null);
	const [channelParams, setchannelParams] = React.useState<
		LiveStreamParamsType[]
	>([]);
	const [videoTracks, setVideoTracks] = React.useState<
		{ trackId: string; uid: UID }[]
	>([]);

	const { isMuted } = usePlayerContext();
	const { videoPlayerRef } = usePlayerState();

	const toggleMute = (isMuted: boolean) => {
		if (channelParams.length > 0) {
			if (isMuted) {
				channelParams.map(
					channel => channel.remoteAudioTrack && channel.remoteAudioTrack.stop()
				);
			} else {
				channelParams.map(
					channel => channel.remoteAudioTrack && channel.remoteAudioTrack.play()
				);
			}
		}
	};

	const checkPip = (tracks: { trackId: string; uid: UID }[]) => {
		if (tracks.length > 0) {
			videoPlayerRef.current = document.getElementById(
				tracks[0].trackId
			) as HTMLVideoElement;
		}
	};

	const connectToLiveStream = async () => {
		try {
			agoraClientRef.current = await AgoraRTC.createClient({
				mode: rtcConfig.mode,
				codec: rtcConfig.codec,
			});

			await agoraClientRef.current.setClientRole(CLIENT_TYPE, { level: 1 });

			await agoraClientRef.current.join(
				subscriberData.appId,
				subscriberData.channel,
				subscriberData.token
			);

			agoraClientRef.current.on("user-published", async (user, mediaType) => {
				switch (mediaType) {
					case "video":
						await agoraClientRef.current?.subscribe(user, mediaType);
						if (user.videoTrack && videoPlayerWrapperRef.current) {
							user.videoTrack.play(videoPlayerWrapperRef.current, {
								fit: "contain",
							});
							setVideoExist(true);
							const videoTrackId = user.videoTrack.getTrackId();

							setVideoTracks(prevState => {
								return [
									...prevState,
									{
										trackId: `video_${videoTrackId}`,
										uid: user.uid,
									},
								];
							});
						}
						break;
					case "audio":
						await agoraClientRef.current?.subscribe(user, mediaType);
						setchannelParams(prevState => {
							return [
								...prevState,
								{
									remoteAudioTrack: user.audioTrack,
									remoteUid: user.uid,
								},
							];
						});
						break;
					default:
						break;
				}
			});

			agoraClientRef.current.on("user-left", async (user, _) => {
				const userLeaving = user.uid;
				setVideoTracks(prevState =>
					prevState.filter(vt => vt.uid.toString() !== userLeaving.toString())
				);
			});

			agoraClientRef.current.on("user-unpublished", async (user, mediaType) => {
				switch (mediaType) {
					case "video":
						await agoraClientRef.current?.unsubscribe(user, mediaType);
						break;
					case "audio":
						await agoraClientRef.current?.unsubscribe(user, mediaType);
						setchannelParams(prevState =>
							prevState.filter(ch => ch.remoteUid !== user.uid)
						);
						break;
					default:
						break;
				}
			});
		} catch (error) {
			console.error("Failed to join the channel:", error);
		}
	};

	React.useEffect(() => {
		connectToLiveStream();
		return () => {
			if (agoraClientRef.current) {
				agoraClientRef.current.removeAllListeners();
				agoraClientRef.current.leave();
			}
		};
	}, []);

	React.useEffect(() => {
		toggleMute(isMuted);
	}, [isMuted]);

	React.useEffect(() => {
		checkPip(videoTracks);
	}, [videoTracks]);

	return (
		<div ref={videoPlayerWrapperRef} className={styles.responsiveGrid}></div>
	);
};
